import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FacebookShareButton,LinkedinShareButton,TwitterShareButton,TwitterIcon,FacebookIcon,LinkedinIcon } from 'react-share';

import { withRouter, Link } from "react-router-dom";
import { pageSectionContent, PageContent, getSetting, setPathLayout, getContentDetails } from "../../actions/Utils";

import Htmlcontent from '../../components/Htmlcontent';
import BreadCrumbs from '../../components/page/BreadCrumbs';
import { image_url, host_name } from '../../config/Api';
import BannerLeft from '../page/BannerLeft';



class ContentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: '',
            contents: null,
            page_content: {},
            content_load: false,
        };
    }

    loadScript = (src) => {
        console.log("load script called")
        return new Promise(function (resolve, reject) {
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
                resolve();
            });
            script.addEventListener('error', function (e) {
                reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
        })
    };
    componentDidMount = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });

        const slugpage = "news";

        // console.log(slug);

        this.props.PageContent(slugpage).then((res) => {

        });




        const { params } = this.props.match;
        this.props.getContentDetails({ slug: params.slug }).then((data) => {
            console.log("pageSectionContent =", data);
            this.setState({
                contents: data,
                content_load: true
            })
        });

        // this.socialShare()

    }

    socialShare = () => {
        console.log("c share called");
        this.loadScript("/vendor_assets/js/jquery/jquery-1.12.3.js")
            .then(() => {
                console.log("one===");
                this.loadScript("https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/highlight.min.js")
                    .then(() => {
                        console.log("two===");
                        this.loadScript('/theme_assets/js/jquery.c-share.js')
                            .then(() => {
                                console.log("three===");
                                console.log("script loaded update");
                                if ($("#shareBlock").children().length == 0) {
                                    window.hljs.initHighlightingOnLoad();
                                    window.$('#shareBlock').cShare({
                                        description: 'jQuery plugin - C Share buttons...',
                                        showButtons: ['fb', 'twitter', 'linkedin']
                                    });
                                }
                            });
                    })
            })
    }

    componentDidUpdate = () => {
        // this.socialShare()
    }

    setPathLayout = (parms) => {
        this.props.setPathLayout(parms);
    }
    getSetting = async () => {
        let data = await this.props.getSetting();
    }
    static getDerivedStateFromProps(props) {
        return {
            path: props.utils.path,
            header: props.header,
            container: props.container,
            footer: props.footer,
            layout_id: props.utils.layout_id,
            lang: props.lang
        };
    }


    render() {

        // console.log("common state =", this.state)
        const { contents, content_load } = this.state;

        const { page_data } = this.props.page_content.content;


        console.log('page_data=', page_data);


        console.log('pagesection contents=', contents);

        return (
            <Fragment>

                {page_data && <Fragment>





                    {contents ? <Fragment>


                   
                        <section class="news-details mb-5 bg-white">
                            <div class="container cotainer_bottom">
                                <div class="news-details-content">
                                    <h4>{contents.title}</h4>
                                    <img src={image_url + '' + contents.image} alt=""></img>
                                    
                                    <ul class="nav">
                                        <li>{contents.publish_date_format}</li>
                                        <li>{contents.category.name}</li>

                                    </ul>
                                    <hr></hr>
                                    {contents.description && <Fragment>
                                        <Htmlcontent data={contents.description} />
                                    </Fragment>}
                                    <hr></hr>
                                    <div class="share-post">
                                        <div class="d-flex  float-right">
                                        <span>Share Post : </span>
                                            {/* <div id="shareBlock"></div> */}
                                            <FacebookShareButton url={`${host_name+'news'+contents.slug}`} className="soical_icon_button pl-1 pr-1"> 
                                                <FacebookIcon className='soical_icon_logo' size={32} round={true}/>
                                            </FacebookShareButton>
                                            
                                            <LinkedinShareButton title={'Hello'}   url={`${host_name+'news'+contents.slug}`} className="soical_icon_button pl-1 pr-1">
                                                <LinkedinIcon className='soical_icon_logo' size={32} round={true}/>
                                            </LinkedinShareButton>

                                            <TwitterShareButton url={`${host_name+'news'+contents.slug}`} className="soical_icon_button pl-1">
                                                <TwitterIcon className='soical_icon_logo' size={32} round={true}/>
                                            </TwitterShareButton>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    
                                </div>
                            </div>
                        </section>


                    </Fragment> : ''}



                </Fragment>}

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    page_content: state.contents.page_content,
    utils: state.utils,
    layout_id: state.utils.layout_id,
    setting: state.utils.setting,
    lang: state.utils.lang

})

const mapDispatchToProps = dispatch => ({
    getSetting: () => dispatch(getSetting()),
    setPathLayout: (requestData) => dispatch(setPathLayout(requestData)),
    PageContent: (requestData) => dispatch(PageContent(requestData)),
    pageSectionContent: (requestData) => dispatch(pageSectionContent(requestData)),
    getContentDetails: (requestData) => dispatch(getContentDetails(requestData)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentDetails));