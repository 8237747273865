import React, { Fragment, useEffect, useState } from "react";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";


  
export const HomeKPI = ({ data }) => {
    useEffect(() => {
        $(document).ready(function () {
            var counted = 0;
            $(window).scroll(function () {
                try {
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (counted == 0 && $(window).scrollTop() > oTop) {
                        $('.count').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            },

                                {

                                    duration: 2000,
                                    easing: 'swing',
                                    step: function () {
                                        $this.text(Math.floor(this.countNum));
                                    },
                                    complete: function () {
                                        $this.text(this.countNum);
                                        //alert('finished');
                                    }

                                });
                        });
                        counted = 1;
                    }
                } catch {
                    // console.log("Error")
                }
            });
        });
    });
    return (
        
        <Fragment>

            <section class="module section__padding">
                <div class="container">
                    <div class="mb-40 text-center">
                    {data && data.map((row, key) => {
                        return (
                            <Fragment>
                                <h3>{row.listcontent.name}</h3>
                            </Fragment>
                        )
                    })}
                    </div>


                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mb-20">

                        {data && data.map((row, key) => {
                            return (
                                <Fragment>
                                {row.list && row.list.map((prow, key) => {
                                    return (
                                        <Fragment>
                                            <div class="col">
                                                <div class="module-single">
                                                    <p><i class="fa fa-solid fa-check"></i> {prow.title ? prow.title : ''}</p>
                                                </div>
                                            </div>
                                        </Fragment>
                                        )
                                    })}

                                </Fragment>
                            )

                        })}
                    </div>

                    <div class="d-table m-auto">
                        <div class="mt-30">
                            <Link  className='btn btn-primary'   to={{ pathname: `/page/richfleet-key-features` }} >View all features</Link>
                            <Link  class='btn text-white ms-2'   to={{ pathname: `/contact` }} >Get a Proposal</Link>
                        </div>
                    </div>
                </div>
            </section>
  

        </Fragment>
    )
}

