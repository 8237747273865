import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Content from '../Content';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { image_url } from "../../../config/Api";
import Htmlcontent from '../../../components/Htmlcontent';


const CommonProduct = ({ page_data }) => {
    const { title, bn_title } = page_data;
    /* const {contents :{short_description=""}={}}=page_data; */
    const { bread_curmbs } = page_data;
    const { image: banner_img = "" } = page_data;
    const { contents } = page_data;
    const { i18n } = useTranslation();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    console.log("contents =",page_data);
    return (
        <Fragment>

        <section class="hero__section">
            <div class="container">
                <div class="row hero-content align-items-center">
                    <div class="col-lg-7">
                        <h1>{page_data.title}</h1>
                        <p class="mb-0">
                            {page_data.contents.short_description}
                        </p>
                    </div>
                    <div class="col-lg-5">
                        <img src={image_url+''+page_data.image}alt="" />
                    </div>
                </div>
            </div>
        </section>

        <div class="bg-white">

            {contents.description &&<Fragment>
                <Htmlcontent data={contents.description} />
            </Fragment>}

        </div>

        </Fragment>
    )
}

export default CommonProduct
