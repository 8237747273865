// import logo from './logo.svg';
import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";

import Cookies from 'js-cookie';
import * as qs from 'query-string';

import { addPushWebToken, getRoutes, getSetting } from './actions/Utils';
// import './App.css';
import Footer from './components/common/Footer';
import CommonHeader from './components/common/header/Commonheader';
import CommonheaderSlider from './components/common/header/CommonheaderSlider';






import Member from './layout/MemberLayout';
import AboutusLayout from './layout/AboutusLayout';


import { base_path } from './config/Api';
import HomeLayout from './layout/Home';
import BlogsLayout from './layout/WebContent';
// import Login from './layout/Login';


// import NewsLayout from './layout/News';
import WebContentLayout from './layout/WebContent';
import InvestmentProcess from './layout/InvestmentProcess';
import Fund from './layout/Fund';
import PageLayout from './layout/Page';
import Product from './layout/Product';

import SearchLayout from './layout/SearchLayout';
import CommonLanding from './page/CommonLanding';
import Contactus from './layout/Contactus';
import Career from './layout/Career';





// Publication component end
import configureStore from './store/Configurstore';


import PeopleDetails from './components/people/PeopleDetails';
import People from './components/people/People';

import ContentDetails from './components/webcontent/ContentDetails';
import PortfolioDetails from './components/portfolio/PortfolioDetails';
import ApplyInvestmentForm from './components/portfolio/ApplyInvestmentForm';
import CorporateSponsorDetails from './components/corporate-sponsor/CorporateSponsorDetails';

// import firebase from './config/Firebase';
// import { getMessaging, getToken } from "firebase/messaging";

// import firebase from 'firebase/compat/app';
// import { getMessaging, getToken ,onMessage} from "firebase/messaging";

// import Notifications from './components/Notifications/Notifications';

import Htmlhelmet from './components/common/HtmlHelmet';


// web user panel


import Portfolio from './components/portfolio/Portfolio';
import WhatWeOffer from './components/home/WhatWeOffer';
import InvestNow from './layout/InvestNow';
import Faq from './layout/Faq';


const store = configureStore();

// const messagingab = getMessaging()
// onMessage(messagingab, (payload) => {
//   console.log("message firebsae payload",payload);
// })



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utils: store.getState().utils,
      routemenu: store.getState().utils.routemenu,
      pubroutemenu: store.getState().utils.pubroutemenu,
      notificaitonshow: false,
      peopleTabSelected: 1,
      notification: {
        title: '',
        body: ''
      }
    };


  }

  handlePushNotification = (token) => {
    var requestData = {
      device_id: token
    }
    store.dispatch(addPushWebToken(requestData)).then((res) => {
      // console.log("handle push notification", res);
    });
  }

  

  componentDidMount = (e) => {
    
    store.dispatch(getRoutes()).then((res) => {
      // console.log(" route data = ",res);
      this.setState({ routemenu: res })
    });
    // store.dispatch(getPubRoutes()).then((res) => {
    //   // console.log(" route data = ",res);
    //   this.setState({ pubroutemenu: res })
    // });

    store.dispatch(getSetting()).then((res) => {
      // console.log('Footer data = ',res);
    });



  }

  render() {
    // console.log('app routemenu js file = ',this.state.routemenu);
    const routemenu = this.state.routemenu;
    const pubroutemenu = [];//this.state.pubroutemenu;

    var site = base_path.split("/");
    // let pathName = this.state.utils.path;
// console.log("site",site);
    var basename = "/";
    if (site.includes("www.tiyait.com") || site.includes("30002")) {
      basename = "/";
    }
    else if (site.includes("dynamic")) {
      basename = "/bd_venture/dynamic/";
    }
    
    else {
      basename = "/";
    }
    //  console.log(" Hello process.env.PUBLIC_URL =",process.env.PUBLIC_URL);
    console.log('basename =',basename);
    return (
      <Router basename={basename}>
        {/* <Router basename={'/publication/'}> */}
        <Fragment>
        
          {/* <Notifications /> */}
          <CommonHeader/>
          <Htmlhelmet />
          <Switch>
            <Route exact path={`/`} >
             
              <HomeLayout />
            </Route>

            {<Route path={`/blogs`}>
              <BlogsLayout />
            </Route> }

            {<Route path={`/contact`}>
              <Contactus />
            </Route> }


            <Route path={`${process.env.PUBLIC_URL}/page/:slug`}>
              <PageLayout />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/product/:slug`}>
              <Product />
            </Route>

 
            <Route path={`${process.env.PUBLIC_URL}/blogsDetails/:slug`} exact>
              <ContentDetails />
            </Route>
            

            
            
            <Route path={`${process.env.PUBLIC_URL}/people/:id/:slug`} exact>
              <PeopleDetails appContext = {this} />
            </Route>


            <Route path={`${process.env.PUBLIC_URL}/portfolio/:slug`} exact>
              <PortfolioDetails />
            </Route>

            <Route path={`${process.env.PUBLIC_URL}/corporate-sponsor/:slug`} exact>
              <CorporateSponsorDetails />
            </Route>


            <Route path={`${process.env.PUBLIC_URL}/search/:slug`}>
              <SearchLayout />
            </Route>

     

            {routemenu.map((menu, index, { length }) => {
              if (menu.layout) {
                //if(index + 1 === length) { 
                if (menu.layout.name.toLowerCase() == 'home') {
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() =>  <HomeLayout />} />
                }

                if (menu.layout.name.toLowerCase() == 'investment') {
                  return <Route key={menu.id} exact path={`${menu.slug}`} render={() => <InvestNow />} />
                }
               
                if (menu.layout.name.toLowerCase() == 'people') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <People appContext = {this} />} />
                }
                 if (menu.layout.name.toLowerCase() == 'about') {
                  // console.log("hello1");
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <AboutusLayout />} />
                 }
                if (menu.layout.name.toLowerCase() == 'portfolio') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Portfolio />} />
                }
                if (menu.layout.name.toLowerCase() == 'faq') {
                  console.log('helloasd',this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Faq />} />
                }
                
                if (menu.layout.name.toLowerCase() == 'contact us') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Contactus />} />
                }
                if (menu.layout.name.toLowerCase() == 'investmentprocess') {
                  console.log('hello',this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <InvestmentProcess />} />
                }
                if (menu.layout.name.toLowerCase() == 'fund') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Fund />} />
                }
                if (menu.layout.name.toLowerCase() == 'content') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <WebContentLayout />} />
                }

                if (menu.layout.name.toLowerCase() == 'career') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <Career />} />
                }
                if (menu.layout.name.toLowerCase() == 'apply for investment') {
                  // console.log(this.props);
                  return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => <ApplyInvestmentForm />} />
                }
                
              }
              else {
                return <Route key={menu.id} exact path={`${process.env.PUBLIC_URL}${menu.slug}`} render={() => '404 NOT FOUND'} />
              }

            })}
          
          </Switch>
          <Footer />
          
        </Fragment>
      </Router>
    );
  }
}

export default App;

