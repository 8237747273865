import React, { Fragment } from 'react';
import BannerLeft from '../BannerLeft';
import BreadCrumbs from '../BreadCrumbs';
import Content from '../Content';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { image_url } from "../../../config/Api";
import Htmlcontent from '../../../components/Htmlcontent';


const Common = ({ page_data }) => {
    const { title, bn_title } = page_data;
    /* const {contents :{short_description=""}={}}=page_data; */
    const { bread_curmbs } = page_data;
    const { image: banner_img = "" } = page_data;
    const { contents } = page_data;
    const { i18n } = useTranslation();
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    console.log("contents =",contents.description);
    return (
        <Fragment>

        <section class="pt-70 pb-70 bg-white">
            <div class="container">
                <div class="pt-50 pb-50 pr-20 pl-20">
                    <div class="title-style-two">
                        <h3>{page_data.title}</h3>
                    </div>
                    {contents.description &&<Fragment>
                        <Htmlcontent data={contents.description} />
                    </Fragment>}

                    {/*page_data.section && page_data.section.map((prow,key)=>{
                        return(
                            <Fragment>

                                {prow.contents[0].description &&<Fragment>
                                    <Htmlcontent data={prow.contents[0].description} />
                                </Fragment>}
                            </Fragment>
                        )
                    })*/}
                    
                </div>
            </div>
        </section>





            <section className="who-we-are-page">
                <div className="container">
                    
                    <div className="inner-content">

                        <Content content={contents} />
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Common
