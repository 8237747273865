import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";
import Img2 from '../../img/icon/icon2.svg';



export const HowWeWork = ({data,fleet,ticket}) => {
    return (

        <Fragment>
            
            <section class="our__services pb-50">
                <div class="container">
                    <div class="row  row-cols-1 row-cols-sm-1 row-cols-md-2  row-cols-lg-2 row-cols-xl-3">

                    {data && data.map((row,key)=>{
                        
                        return(
                            <Fragment>
                                <div class="col mb-30">
                                     <div class="service-single">

                                        <img src={image_url+''+row.listcontent.image} alt=""></img>
                                        <h3>{row.listcontent.title}</h3>


                                        <ul>
                                            {row.list && row.list.map((prow,key)=>{
                                                return(
                                                    <Fragment>

                                                        <li>
                                                            {prow.title}
                                                        </li>
                                                    </Fragment>
                                                )
                                            })}

                                        </ul>

                                        <Link  className='more-link'   to={{ pathname: `/product/vehicle-management-system` }} >More Details <i class="fa fa-angle-right"></i></Link>
                                     </div>
                                </div>

                            </Fragment>
                        )
                    })}

                    {fleet && fleet.map((row,key)=>{
                        return(
                            <Fragment>
                                <div class="col mb-30">
                                     <div class="service-single">

                                        <img src={image_url+''+row.listcontent.image} alt=""></img>
                                        <h3>{row.listcontent.title}</h3>


                                        <ul>
                                            {row.list && row.list.map((prow,key)=>{
                                                return(
                                                    <Fragment>

                                                        <li>
                                                            {prow.title}
                                                        </li>
                                                    </Fragment>
                                                )
                                            })}

                                        </ul>


                                        <Link  className='more-link'   to={{ pathname: `/product/fleet-management-system` }} >More Details <i class="fa fa-angle-right"></i></Link>
                                     </div>
                                </div>

                            </Fragment>
                        )
                    })}

                    {ticket && ticket.map((row,key)=>{
                        return(
                            <Fragment>
                                <div class="col mb-30">
                                     <div class="service-single">

                                        <img src={image_url+''+row.listcontent.image} alt=""></img>
                                        <h3>{row.listcontent.title}</h3>


                                        <ul>
                                            {row.list && row.list.map((prow,key)=>{
                                                return(
                                                    <Fragment>

                                                        <li>
                                                            {prow.title}
                                                        </li>
                                                    </Fragment>
                                                )
                                            })}

                                        </ul>

                                        <Link  className='more-link'   to={{ pathname: `/product/ticket-management` }} >More Details <i class="fa fa-angle-right"></i></Link>
                                    </div>
                                </div>

                            </Fragment>
                        )
                    })}
                       
                    </div>
                </div>
            </section>
        </Fragment>
        
    )
}

