import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { image_url } from "../../config/Api";



export const Ecosystem = ({data}) => {
    return (
        
        <Fragment>
            {data && data.map((row,key)=>{
           
                return(
                    <Fragment>

                    <section class="why__choice bg-white section__padding">
                        <div class="container">


                            <div class="text-center title-style-one">
                                <h1>{row.listcontent.title}</h1>
                                <p>{row.listcontent.description}</p>
                            </div>



                            <div class="row  row-cols-1 row-cols-sm-1 row-cols-md-2  row-cols-lg-2 row-cols-xl-3">
                                {row.list && row.list.map((prow,key)=>{

                                    return(
                                        <Fragment>

                                            <div class="col mb-30">
                                                 <div class="choice-single">
                                                    <h2>{prow.bn_title}</h2>
                                                    <p>{prow.title}</p>
                                                 </div>
                                            </div>
                                        </Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </section>

                    </Fragment>
                )
            })}


        </Fragment>
    )
}

