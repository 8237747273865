import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
import { image_url } from '../../config/Api';
import Cookies from 'js-cookie';



export const Modules = ({data}) => {
    var slider = [];
    var content = [];

        if (data) {

            const section_data = data.find((row) => {
                if (row.typename == 'Testimonial') {
                    return true;
                }
                else {
                    return false;
                }
            });

            if (section_data) {
                slider = section_data.slider;
                content = section_data;
            }
        }

    var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3500,
            arrows: false,
            loop: true,
            margin: 30,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 300,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
    
    return (

        <Fragment>

            <section class="text-center testimonial section__padding">
                <div class="container">

                    <h4>{content.typename}</h4>
                    <div class="text-center title-style-one">
                        <h1>{content.short_description}</h1>
                        <p>{content.description}</p>
                    </div>

                    <div className="text-center testimonial__slider ">
                        <Slider
                            {...settings}
                        >
                            {
                                slider.map((row, key) => {

                                    return (
                                        <Fragment key={key}>
                                            <div>
                                                <div className="our-product-slide module__slider__item">
                                                    <h5>{row.short_descripton}</h5>
                                                    <h4>{row.bn_title}</h4>
                                                    <p><strong>{row.title}</strong></p>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })

                            }
                        </Slider>
                    </div>


                </div>
            </section>


            
        


        </Fragment>
    )
}

